import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

export default function References() {
  return (
    <Container disableGutters>
      <Typography variant="h4" component="h1">Referenser</Typography>
      <Typography variant="h5" component="h2">Urval</Typography>
      <Typography variant="h6" component="h3">2023</Typography>
      <ul>
        <li>Gardinlösning hotell, Stockholm</li>
        <li>Gardinlösning hotell, Bålsta</li>
        <li>Omklädnad kontorslandskap, Solna</li>
        <li>Omlackering stolar, Stockholm</li>
        <li>Omklädnad restaurang, Stockholm</li>
        <li>Specialtillverkning inredning restaurang, Solna</li>
        <li>Restauranginredning, Hallstahammar</li>
        <li>Butiksinredning, hela Sverige</li>
        <li>Speciallösning stoppmöbler restaurang, Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2022</Typography>
      <ul>
        <li>Omklädnad kontorsinredning, Stockholm</li>
        <li>Nyproduktion specialsnickerier, kontorslandskap</li>
        <li>Nyproduktion fast inredning hotell, Stockholm</li>
        <li>Butiksinredning, hela Sverige</li>
        <li>Restauranginredning, Västerås</li>
        <li>Speciallösning stoppmöbler restaurang, Stockholm</li>
        <li>Speciallösning bord restaurang, Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2021</Typography>
      <ul>
        <li>Omklädnad bordsskärmar, Stockholm</li>
        <li>Restauranginredning, Visby</li>
        <li>Restauranginredning, Stockholm</li>
        <li>Omklädnad restaurang, Stockholm</li>
        <li>Omklädnad kontorsinredning, Stockholm</li>
        <li>Butiksinredning, hela Sverige</li>
      </ul>
      <Typography variant="h6" component="h3">2020</Typography>
      <ul>
        <li>Ny lös inredning och förslag fast inredning - Öhrmans café Västerås</li>
        <li>Nya utemöbler – Hotell Point Stockholm</li>
        <li>Gardinlösning restaurang – Pixel Solna</li>
        <li>KA arbete – Klubbhuset, Hackholmssunds konferens Bro</li>
        <li>Skräddarsydd inredning restaurang – Diwan Stockholm</li>
        <li>Kylrumsinredning – Interflora, ett flertal butiker över hela landet</li>
        <li>Leverans solskydd – Restaurang Fejan Stockholms skärgård</li>
        <li>Omklädnad stolar – Tenant & Partner Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2019</Typography>
      <ul>
        <li>Överdrag sjuan stolar – Tenant & Partner Stockholm</li>
        <li>Överdrag stolar sportbar – Solna</li>
        <li>Inredning hotellrum – Marriott Courtyard Stockholm</li>
        <li>Projektledning invändig miljöstation – SUEZ</li>
        <li>Skräddarsydda bordset – Restaurang Cielo Stockholm</li>
        <li>Skräddarsydd specialinredning lobbymiljö – Marriott Courtyard Stockholm</li>
        <li>Projektledning tillbyggnad – Eurocash Storlien</li>
        <li>Leverans metallinredning – Wexthuset Enhörna</li>
        <li>Leverans fåtöljer hotellrum – Marriott Courtyard Stockholm</li>
        <li>Leverans blomsterlådor utemiljö – Lydmar Hotel Stockholm</li>
        <li>Omklädnad loungemöbler – Tenant & Partner Stockholm</li>
        <li>Leverans butiksinredning Sollentuna, Trollhättan m.fl.</li>
        <li>Omklädnad dynor – Vau de Ville Stockholm</li>
        <li>Leverans solskydd – Restaurang Fejan Stockholms skärgård</li>
        <li>Konceptinredning kylrum – Interflora</li>
        <li>Skräddarsydd inredning utemiljö – Strandvägen 1 Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2018</Typography>
      <ul>
        <li>Leverans mattor korridorer & konferensrum – Aronsborg Konferenshotell AB</li>
        <li>Omklädnad restaurangsoffor – Marriott Courtyard Stockholm</li>
        <li>Leverans fåtöljer hotellrum – Marriott Courtyard Stockholm</li>
        <li>Gardinlösning reception – Hotel Point Stockholm</li>
        <li>Skräddarsydd inredning utemiljö – Strandvägen 1 Stockholm</li>
        <li>Leverans konferensstolar – Nox Consulting Stockholm</li>
        <li>Omklädnad stolar – Tenant & Partner Stockholm</li>
        <li>Renovering barstolar i skinn – Marriott Courtyard Stockholm</li>
        <li>Leverans fällbara ståbord – Aronsborg Konferenshotell AB</li>
      </ul>
      <Typography variant="h6" component="h3">2017</Typography>
      <ul>
        <li>Ombyggnation av konferenslokaler – Aronsborg Konferenshotell AB</li>
        <li>Dekorationer till Nobelmiddagen – Benjamins Botaniska AB</li>
        <li>Inredning hotellrum, lobby & restaurang – Courtyard by Marriott</li>
        <li>Inredning utemiljö – Courtyard by Marriott</li>
        <li>Butiksinredning – blomsterbutiker och florister över hela landet, anpassade lösningar</li>
        <li>Inredning utemiljö – butiker och gardencenters över hela landet och i Finland</li>
        <li>Komplettering utemiljö – Lydmar Hotel</li>
        <li>Restauranginredning – Levinskys</li>
        <li>Komplettering utemiljö – Sagolekhuset Junibacken AB</li>
        <li>Omklädnad inredning – Tenant & Partner AB</li>
        <li>Komplettering utemiljö – Vasamuseets Restaurang AB</li>
      </ul>
      <Typography variant="h6" component="h3">2016</Typography>
      <ul>
        <li>Komplettering restauranginredning – Vau De Ville, Stockholm</li>
        <li>Komplettering kontorsinredning – Håbohus, Bålsta</li>
        <li>Omklädnad designfåtöljer – Clarion Hotel Sign, Stockholm</li>
        <li>Ny restauranginredning – Bistro Linné, Stockholm</li>
        <li>Nya gardiner – Clarion Collection Hotel Tapto, Stockholm</li>
        <li>Nya gardiner & draperier – Bistro linné, Stockholm</li>
        <li>Nya mattor – Aronsborgs konferenshotell, Bålsta</li>
        <li>Nya mattor – Clarion Hotel Sign, Stockholm</li>
        <li>Ny, skräddarsydd loungeinredning – Courtyard by Marriott, Stockholm</li>
        <li>Ny, skräddarsydd hotellrumsinredning – Courtyard by Mariott, Stockholm</li>
        <li>Komplettering utemiljö – Hotel Lydmar, Stockholm</li>
        <li>Ny butiksinredning – 10-talet blomsterbutiker runt om i landet</li>
        <li>Ny utemiljö – Junibacken, Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2015</Typography>
      <ul>
        <li>Tillverkning av skräddarsydda soffor – Bistro Linné, Stockholm</li>
        <li>Nytillverkning av sittdynor –  Café Levinskys, Stockholm</li>
        <li>Tillverkning av soffbord – Bistro Linné, Stockholm</li>
        <li>Omklädnad stoppmöbler – Tenant & partner AB, Stockholm</li>
        <li>Leverans av belysning – Bistro Linné, Stockholm</li>
        <li>Omklädnad stoppmöbler, ägget-fåtöljer – Clarion Hotel Sign, Stockholm</li>
        <li>Ombyggnation av hotellrum, tillverkning och leverans av lös och fast inredning och textilier –  Clarion Collection Hotel Tapto, Stockholm</li>
        <li>Tillverkning och leverans av hotellrumsinredning –  Lägenhetshotell Biz Apartment, Stockholm</li>
        <li>Tillverkning av läderdraperi –  Restaurang Vau De Ville, Stockholm</li>
        <li>Om och nytillverkning av sittdynor –  Restaurang Carousel, Stockholm</li>
        <li>Tillverkning av barstolar och fåtöljer, leverans av bord – Bistro Linné, Stockholm</li>
        <li>Projektledning, tillverkning och leverans av möbler till kontor – Håbohus, Bålsta</li>
        <li>Omklädnader av fåtöljer –  Hotell Sven Vintappare, Stockholm</li>
        <li>Leverans av konferensbord till konferensrum – Clarion Collection Hotel Tapto, Stockholm</li>
        <li>Tillverkning av utomhusmöbler –  Restaurang El Cielo, Stockholm</li>
        <li>Tillverkning av läderdynor till bänkar och pallar –  Restaurang Vau De Ville, Stockholm</li>
        <li>Leverans av restaurangstolar –  Hotell Renaissance Malmö</li>
        <li>Omklädnader av fåtöljer i lobbyn och leverans av restaurangstolar till Courtyard by Marriott, Stockholm</li>
        <li>Sömnad och uppsättning av gardiner till konferensrum – Clarion Collection Hotel Tapto, Stockholm</li>
        <li>Sömnad och uppsättning av terassgardiner – Hotell Lydmar, Stockholm</li>
        <li>Omklädnad stoppmöbler – Clarion Hotel Sign, Stockholm</li>
        <li>Leverans av konferensbord – Sam Consulting, Stockholm</li>
        <li>Ombyggnation och inredning nattklubb – Criollo Nightclub Aronsborgs konferenshotell, Bålsta</li>
        <li>Design, projektledning och inredning 51 hotellrum – Aronsborgs konferenshotell, Bålsta</li>
        <li>Ombyggnation och inredning nattklubb – Criollo Nightclub Aronsborgs konferenshotell, Bålsta</li>
        <li>Förslag ombyggnad och ny inredning kontor – Håbohus, Bålsta</li>
      </ul>
      <Typography variant="h6" component="h3">2014</Typography>
      <ul>
        <li>Mattläggning korridorer & hotellrum – Radisson Blu Royal Viking hotell, Stockholm</li>
        <li>Design, projektledning och inredning – ICA Kvantum Back-office, Bålsta</li>
        <li>Möblering 12 hotellrum – Aronsborgs konferenshotell, Bålsta</li>
        <li>Omklädnad stoppmöbler – Clarion Collection Hotel Tapto, Stockholm</li>
        <li>Omklädnad stoppmöbler – Clarion Hotel Sign, Stockholm</li>
        <li>Omklädnad stoppmöbler – Radisson Blu Royal Viking hotell, Stockholm</li>
        <li>Komplettering inredning – Hotell Lydmar, Stockholm</li>
        <li>Design & ombyggnation samt inredning 48 hotellrum – Aronsborgs konferenshotell, Bålsta</li>
        <li>Design & ombyggnation samt inredning ny butik – HONN juvelerarbutik, Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2013</Typography>
      <ul>
        <li>Komplettering av inredning , Hotell Lydmar, Stockholm</li>
        <li>Nya gardiner, Hotell  Gillet, Uppsala</li>
        <li>Fontän med skräddarsytt barbord, Svenssons Krogar, Uppsala</li>
        <li>Inredning till back-office lösning,  Aronsborgs Konferenshotell, Bålsta</li>
        <li>Labofa stolar till lilla köket Aronsborgs Konferenshotell, Bålsta</li>
        <li>Omklädnad av stolar, Clarion Sign Hotel, Stockholm</li>
        <li>Utemöbler till Vitsippans trygghetsboende Kustbostäder, Oxelösund</li>
        <li>Skräddarsydd tapetlösning m. sjökort, Jogersö Bad & Camping, Oxelösund</li>
        <li>Mattläggning allmänna utrymmen, Selma Spa, Sunne</li>
        <li>Komplettering caféinredning, Café Chazalle, Stockholm</li>
      </ul>
      <Typography variant="h6" component="h3">2012</Typography>
      <ul>
        <li>Design och produktion av restauranginredning, Restaurang Colosseo, Eskilstuna</li>
        <li>Mattläggning allmänna utrymmen, Quality Airport Hotel, Arlanda</li>
        <li>Design och produktion av butiksinredning, Spångbergs Blommor, T-Centralen</li>
        <li>Design och produktion av specialtapet, NCC, Sörmland</li>
        <li>Design och produktion av ny restaurangsoffa, Café Chazalle, Stockholm</li>
        <li>Komplettering av kontorsinredning, Rikshem, Stockholm</li>
        <li>Komplettering lös inredning, Hotell Clarion Gillet, Uppsala</li>
        <li>Design och produktion av ny spainredning, Aronsborgs Konferenshotell, Bålsta</li>
        <li>Mattläggning allmänna utrymmen, Hotel Scandic, Kungens Kurva</li>
      </ul>
      <Typography variant="h6" component="h3">2011</Typography>
      <ul>
        <li>Mattläggning i allmänna utrymmen på 7 våningsplan, hotell Nordic Sea, Stockholm</li>
        <li>Komplettering av inredning, Cape East hotell, Haparanda</li>
        <li>Omklädnad stoppmöbler Hotel Clarion Gillet, Uppsala, Aronsborg Konferens Hotell, Bålsta och Hotell Lydmar, Stockholm</li>
        <li>Mattläggning i konferensavdelning, Hotel Clarion Gillet, Uppsala</li>
        <li>Design och produktion av nya barer och nya utemöbler, Lydmar Hotell, Stockholm</li>
        <li>Totalrenovering av matsal med nya möbler, Adlon Hotell, Stockholm</li>
        <li>Nya stoppmöbler och omklädnad av befintlig restaurangsoffa, Café Chazalle, Stockholm</li>
        <li>Design och produktion av kontorsinredning till Kontorshotell Briggert & Söderberg, Stockholm</li>
        <li>Omklädnad av stoppmöbler samt justering av befintliga möbler, Hotell Globen, Stockholm.</li>
        <li>Design och produktion av kontorsinredning, Dombron Fastigheter, Stockholm</li>
      </ul>
    </Container>
  );
}
