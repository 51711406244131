import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container, List, ListItem } from '@mui/material';

export default function Products() {
  return (
    <Container disableGutters>
      <Typography variant="h4" component="h1">Produkter</Typography>
      <Typography variant="h5" component="h2">Brunner</Typography>
      <Typography variant="p" component="p">Nils Holger Arkitekter & Inredare representerar det tyska möbelföretaget Brunner Group i Mälardalen och Mellansverige. Brunner Group är en stark partner som omsätter över en miljard svenska kronor årligen och som erbjuder ett omfattande produktsortiment inom:</Typography>
      <ul>
        <li>Office</li>
        <li>Conference</li>
        <li>Event</li>
        <li>Cafeteria</li>
        <li>Lounge</li>
        <li>Care</li>
      </ul>
      <Typography variant="h5" component="h2">Andra kända tillverkare</Typography>
      <Typography variant="p" component="p">Vi erbjuder ett stort urval av fast och lös inredning för alla typer av offentliga miljöer och för olika funktioner. Vi samarbetar med de flesta kända varumärken som finns på marknaden och kan erbjuda de flesta befintliga modeller när detta är föreskrivet. </Typography>
      <Typography variant="h5" component="h2">Egen tillverkning</Typography>
      <Typography variant="p" component="p">Vi har också egen tillverkning av både fast och lös inredning från ritning. Ibland ställs det krav på flexibel inredning med specialmått och egen design – och just detta skiljer oss från många av våra konkurrenter. Vi erbjuder specialanpassade lösningar genom egen tillverkning i de flesta material.</Typography>
      <Typography variant="h5" component="h2">Omklädnader och reparation</Typography>
      <Typography variant="p" component="p">Som ett hållbart och prisvärt alternativ i dagens ”slit & släng samhälle” erbjuder vi omklädnad och uppfräschning av alla typer av stoppade möbler och inredningsdetaljer.</Typography>
      <Typography variant="p" component="p">​Stommen i stoppade möbler är ofta i utmärkt skick även efter många år i bruk även om själva ytan kanske har gjort sitt. Stolar, soffor och fåtöljer med smutsigt eller trasigt tyg blir som nya efter en omklädnad. Vi har stor erfarenhet av omklädnader och har klätt om tusentals stolar och fåtöljer till kontor, hotell och restauranger.</Typography>
      <Typography variant="p" component="p">Och skulle det behövas finns det ofta möjlighet att byta ut eller förstärka stoppning och olika typer av mekanismer (t.ex. fjädrar) som kan ha mattats av över tiden samtidigt som möbler kläs om. Vi erbjuder även reparation och renovering av andra inredningsdetaljer som t.ex. slipning av bordsskivor m.m. Att klä om möbler är en riktigt hållbar lösning. Förutom att det lönar sig rent ekonomiskt så innebär det ALLTID en miljömässig vinst.</Typography>
    </Container>
  );
}
