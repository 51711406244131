import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Root from './routes/Root';
import MainTheme from './themes/main-theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './routes/ErrorPage';
import Home from './routes/Home';
import About from './routes/About';
import Jobs from './routes/Jobs';
import Products from './routes/Products';
import Portfolio from './routes/Portfolio';
import Brunner from './routes/Brunner';
import Contact from './routes/Contact';
import References from './routes/References';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/om-oss",
        element: <About />
      },
      {
        path: "/uppdrag",
        element: <Jobs />
      },
      {
        path: "/produkter",
        element: <Products />
      },
      {
        path: "/portfolio",
        element: <Portfolio />
      },
      {
        path: "/referenser",
        element: <References />
      },
      {
        path: "/brunner",
        element: <Brunner />
      },
      {
        path: "/kontakt",
        element: <Contact />
      }
    ]
  },
]);

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ThemeProvider theme={MainTheme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>,
);
