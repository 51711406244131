import * as React from 'react';
import Typography from '@mui/material/Typography';
import PortfolioImages from '../components/PortfolioImages';
import { Container } from '@mui/material';

export default function Portfolio() {
  return (
    <Container disableGutters>
      <Typography variant="h4" component="h1">
        Portfolio
      </Typography>
      <PortfolioImages />
    </Container>
  );
}
