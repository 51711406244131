import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

export default function Contact() {
  return (
    <Container disableGutters>
      <Typography variant="h4" component="h1">Kontakt</Typography>
      <Typography variant="p" component="p">Birger Jarlsgatan 114</Typography>
      <Typography variant="p" component="p">11420 Stockholm</Typography>
      <Typography variant="p" component="p">Tel: +46 - 08 673 50 80</Typography>
      <Typography variant="p" component="p">info@nilsholger.se</Typography>
    </Container>
  );
}
