import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

export default function Home() {
  return (
    <Container disableGutters>

      <Typography variant="h4" component="h1">
        <img src='/images/nh_2024_logo.jpg' height={150} />
      </Typography>

      <Typography variant="p" component="p">
        Vår affärsidé är att vara en pålitlig och trevlig partner när det gäller inredning och byggnation för alla typer av offentliga projekt. Vi arbetar i såväl interiöra som exteriöra miljöer.
      </Typography>

      <Typography variant="p" component="p">
        Välkomna till Nils Holger – Furniture & Project
      </Typography>

    </Container>
  );
}
