import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

export default function Jobs() {
  return (
    <Container disableGutters>
      <Typography variant="h4" component="h1">Uppdrag</Typography>
      <Typography variant="h5" component="h2">Projektledning</Typography>
      <Typography variant="p" component="p">Inget projekt är det andra likt! Därför ser vi gärna att ett första möte blir en förutsättningslös diskussion om målbild &amp; upplägg, omfattning, tidplan och ekonomi. Utifrån dessa givna ramar tar vi fram ett förslag på lösning oavsett innehåll.</Typography>
      <Typography variant="p" component="p">Inget projekt är för litet. Det kan t.ex. handla om att uppgradera en hotellobby, bygga om ett kontor, inreda nya hotellrum, skräddarsy en restaurang eller varför inte inreda en butik. Men det kan också handla om att bygga en helt ny byggnad eller att bygga om/ bygga till en befintlig. Oavsett vad så tar vi hand om ert projekt från start till mål!</Typography>
      <Typography variant="h5" component="h2">Kontrollansvar (KA)</Typography>
      <Typography variant="p" component="p">Många ny-, om- eller tillbyggnadsprojekt kräver en kontrollansvarig funktion. Vi är certifierade med behörighet K.</Typography>
      <Typography variant="h5" component="h2">Offentlig inredning</Typography>
      <Typography variant="p" component="p">​Avser alla typer av offentliga miljöer. Det handlar ofta om att ta fram inredning utifrån specifikation från arkitekt och/eller inredare. Men vi har också egen kompetens att designa och utforma den typ av inredning som efterfrågas. Ibland handlar det om all inredning i ett projekt, , vid andra tillfällen handlar det mer om att ta fram någon särskild inredningsdetalj eller att komplettera befintliga möbler till en verksamhet. Vi är också duktiga på att klä om och/eller renovera befintliga möbler vilket nästan alltid är ett hållbart och prisvärt alternativ.</Typography>
      <Typography variant="p" component="p">Vi arbetar med alla kända tillverkare i Norden och övriga Europa, men vi har också en egen tillverkning där vi kan tillverka i stort sett alla material direkt från ritning.</Typography>
      <Typography variant="h5" component="h2">Byggnation och renovering</Typography>
      <Typography variant="p" component="p">Med hjälp av vårt stora nätverk av underentreprenörer agerar vi som projekt- och/eller byggledare för i stort sett alla typer av arbeten inom byggnation och inredning. En enkel och prisvärd lösning för beställaren.</Typography>
      <Typography variant="p" component="p">I alla typer av lokaler blir det ofrånkomligen slitage på ytskikten. Ibland kan detta innebära patina och en känsla av trivsel, men oftast så innebär det att helhetsintrycket dras ned och att det är läge att fräscha upp eller byta ut vissa delar. Vi har stor erfarenhet av att snabbt och effektivt förändra offentliga rum så att verksamheten drabbas så lite som möjligt, enkelt och prisvärt.</Typography>
      <Typography variant="p" component="p">När projekten är av större omfattning åtar vi oss självklart att hantera underentreprenörer inom t.ex. El, VVS, konstruktion, brand, tillgänglighet m.fl. som kan bli aktuella.</Typography>
    </Container>
  );
}
