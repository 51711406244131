import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

export default function About() {
  return (
    <Container disableGutters>

      <Typography variant="h4" component="h1">
        Om oss
      </Typography>

      <Typography variant="h5" component="h2">
        Här finns vi
      </Typography>

      <Typography variant="h6" component="h3">
        Utställning/Kontor
      </Typography>

      <Typography variant="p" component="p">
        I vår utställning på Birger Jarlsgatan 114 i Stockholm visar vi framför allt möbler från Brunner Group men även en del andra produkter.
      </Typography>

      <Typography variant="p" component="p">
        Vi träffas i vår utställning efter avtalad tid. Men oftast är det vi själva som åker ut till våra beställare på plats.
      </Typography>

      <Typography variant="p" component="p">
        Nils Holger – Furniture & Project<br />
        Birger Jarlsgatan 114<br />
        114 20 Stockholm
      </Typography>

    </Container>
  );
}
