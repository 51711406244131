import * as React from 'react';
import { ImageList, ImageListItem, Container } from '@mui/material';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function PortfolioImages() {
    const [open, setOpen] = React.useState(false);
    const [startingIndex, setStartingIndex] = React.useState(0);

    return (
        <Container disableGutters>
            <ImageList sx={{ width: 500 }} cols={3} rowHeight={164}>
                {images.map((item, index) => (
                    <ImageListItem key={item.src} onClick={() => { setOpen(true); setStartingIndex(index) }}>
                        <img
                            srcSet={`${item.src}`}
                            src={`${item.src}`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={images}
                index={startingIndex}
            />
        </Container >
    )
};

const images = [
    {
        src: '/images/portfolio/nynashamn_obos1.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/lydmar_terrass1.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/nykvarn.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/clarion_tapto.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/sundbyberg.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/nynashamn_obos2.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/lydmar_terrass2.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/hagerneholm_obos_vardagsrum.jpg',
        title: 'desc',
    },
    {
        src: '/images/portfolio/linne_bistro.jpg',
        title: 'desc',
    }
];