import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

export default function Brunner() {
  return (
    <Container disableGutters>
      <Typography variant="h4" component="h1">Brunner</Typography>
      <Typography variant="p" component="p">Nils Holger Furniture &a Project representerar det tyska möbelföretaget Brunner Group i Mälardalen och Mellansverige. Brunner Group är en stark partner som omsätter över en miljard svenska kronor årligen och som erbjuder ett omfattande produktsortiment inom:</Typography>
      <ul>
        <li>Office</li>
        <li>Conference</li>
        <li>Event</li>
        <li>Cafeteria</li>
        <li>Lounge</li>
        <li>Care</li>
      </ul>
      <Typography variant="p" component="p">I vårt showroom på Birger Jarlsgatan 114 i Stockholm finns en hel del inredning att testa och för påseende.</Typography>
    </Container>
  );
}
