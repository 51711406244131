import * as React from 'react';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import DrawerAppBar from '../components/DrawerAppBar';

export default function Root() {
  return (
    <Container maxWidth="false" disableGutters>
      <DrawerAppBar />
      <Container maxWidth="sm">
        <br />
        <Outlet />
      </Container>
    </Container>
  );
}
